<template>
  <div id="app" class="relative">
    <button
      @click="resetPapers"
      class="reset-button -mt-32 bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-full absolute right-1 focus:outline-none shadow-md transition duration-300 ease-in-out"
    >
      Reset Papers
    </button>
    <div id="loveletter" style="display: none"><LoveLetter /></div>

    <div class="mt-32">
      <div class="paper heart"></div>
      <div
        v-for="(paper, index) in papers"
        :key="index"
        class="paper"
        :class="paper.class"
      >
        <p
          v-for="(line, lineIndex) in paper.content"
          :key="`${index}-${lineIndex}`"
        >
          {{ line }}
        </p>
        <img v-if="paper.image" :src="paper.image" class="paper-image" />
      </div>
    </div>
  </div>
</template>

<script>
import LoveLetter from "./LoveLetter.vue";

export default {
  name: "DragPaper",
  components: {
    LoveLetter,
  },
  data() {
    return {
      highestZ: 1,
      papers: [
      { content: ["This heart reveals", "something! Maybe", "move it around?"], class: "red" },
      {
          content: ["How can", "someone be so cute ❤️"],
          image: require("@/assets/10.jpeg"),
          class: "image",
        },
        {
          content: ["a piece of art besides you"],
          image: require("@/assets/11.jpeg"),
          class: "image",
        },
        {
          content: ["gooflords"],
          image: require("@/assets/12.jpeg"),
          class: "image",
        },
        {
          content: ["shopping like usual"],
          image: require("@/assets/13.jpeg"),
          class: "image",
        },
        {
          content: ["mannheim gaming"],
          image: require("@/assets/14.jpeg"),
          class: "image",
        },
        {
          content: ["mcdonalds and cigarettes"],
          image: require("@/assets/9.jpeg"),
          class: "image",
        },
        {
          content: ["wasn't that my favorite picture of us?"],
          image: require("@/assets/8.jpeg"),
          class: "image",
        },
        {
          content: ["oh bebe"],
          image: require("@/assets/7.jpeg"),
          class: "image",
        },
        {
          image: require("@/assets/6.jpeg"),
          class: "image",
        },
        {
          content: ["Our first insta story together"],
          image: require("@/assets/5.jpeg"),
          class: "image",
        },
        {
          content: ["Our famous picture"],
          image: require("@/assets/4.jpeg"),
          class: "image",
        },
        { content: [""], image: require("@/assets/3.jpeg"), class: "image" },
        { content: ["And btw i luv u ofc"], class: "red" },
        { content: [""], class: "image", image: require("@/assets/1.jpeg") },
        { content: ["ig hier sind", "ein paar Bilder", "von uns"], class: "purple"},
        { content: ["Happy Birthday 🎂"], class: "purple"},
        { content: ["Hey my love"], class: "purple"} ,
      ],
    };
  },
  mounted() {
    const paperElements = this.$el.querySelectorAll(".paper");
    paperElements.forEach((paper) => {
      this.initPaper(paper);
    });
    window.addEventListener("mouseup", this.onMouseUp);
    window.addEventListener("touchend", this.onMouseUp);

    document.body.style.overflow = "hidden";
  },
  beforeUnmount() {
    window.removeEventListener("mouseup", this.onMouseUp);
    window.removeEventListener("touchend", this.onMouseUp);
  },
  methods: {
    initPaper(paper) {
      let holdingPaper = false;
      let rotating = false;
      let mouseTouchX = 0;
      let mouseTouchY = 0;
      let mouseX = 0;
      let mouseY = 0;
      let prevMouseX = 0;
      let prevMouseY = 0;
      let velX = 0;
      let velY = 0;
      let rotation = Math.random() * 30 - 15;
      let currentPaperX = 0;
      let currentPaperY = 0;
      let firstTouch = true;

      const centerPaper = () => {
        const containerRect = this.$el.getBoundingClientRect();
        const paperRect = paper.getBoundingClientRect();
        currentPaperX = (containerRect.width - paperRect.width) / 2;
        currentPaperY = (containerRect.height - paperRect.height) / 2;
        paper.style.transform = `translateX(${currentPaperX}px) translateY(${currentPaperY}px) rotateZ(${rotation}deg)`;
      };
      const handleMouseMove = (e) => {
        onMouseMove(e);
      };

      const handleTouchMove = (e) => {
        if (firstTouch) {
          firstTouch = false;
          rotation = Math.random() * 30 - 15;
        }
        const touch = e.touches[0];
        onMouseMove({ clientX: touch.clientX, clientY: touch.clientY });
      };

      const onMouseMove = (e) => {
        if (!rotating) {
          mouseX = e.clientX;
          mouseY = e.clientY;
          velX = mouseX - prevMouseX;
          velY = mouseY - prevMouseY;
        }
        const dirX = e.clientX - mouseTouchX;
        const dirY = e.clientY - mouseTouchY;
        const dirLength = Math.sqrt(dirX * dirX + dirY * dirY);
        const dirNormalizedX = dirX / dirLength;
        const dirNormalizedY = dirY / dirLength;
        const angle = Math.atan2(dirNormalizedY, dirNormalizedX);
        let degrees = (180 * angle) / Math.PI;
        degrees = (360 + Math.round(degrees)) % 360;
        if (rotating) {
          rotation = degrees;
        }
        if (holdingPaper) {
          if (!rotating) {
            currentPaperX += velX;
            currentPaperY += velY;
          }
          prevMouseX = mouseX;
          prevMouseY = mouseY;
          paper.style.transform = `translateX(${currentPaperX}px) translateY(${currentPaperY}px) rotateZ(${rotation}deg)`;
        }


      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("touchmove", handleTouchMove);
      paper.handleMouseMove = handleMouseMove;

      paper.addEventListener("mousedown", (e) => {
        if (holdingPaper) return;
        else {
          holdingPaper = true;
          paper.style.zIndex = this.highestZ;
          this.highestZ += 1;
          if (e.button === 0) {
            mouseTouchX = mouseX;
            mouseTouchY = mouseY;
            prevMouseX = mouseX;
            prevMouseY = mouseY;
          }
          if (e.button === 2) {
            rotating = true;
          }
          if (paper === this.$el.querySelectorAll(".paper")[0]) {
            document.getElementById("loveletter").style.display = "block";
          }
        }
      });

      paper.addEventListener("touchstart", (e) => {
        if (holdingPaper) return;
        else {
          holdingPaper = true;
          paper.style.zIndex = this.highestZ;
          this.highestZ += 1;
          const touch = e.touches[0];
          mouseTouchX = touch.clientX;
          mouseTouchY = touch.clientY;
          prevMouseX = touch.clientX;
          prevMouseY = touch.clientY;

          if (paper === this.$el.querySelectorAll(".paper")[0]) {
            document.getElementById("loveletter").style.display = "block";
          }
        }
      });

      paper.addEventListener("contextmenu", (e) => e.preventDefault());
      paper.addEventListener("mouseup", () => {
        holdingPaper = false;
        rotating = false;
      });
      paper.addEventListener("touchend", () => {
        holdingPaper = false;
        rotating = false;
      });
      centerPaper();
      window.addEventListener("resize", centerPaper);
    },
    onMouseUp() {
      this.papers.forEach((paper) => {
        paper.holdingPaper = false;
        paper.rotating = false;
        document.dispatchEvent(new Event("mouseup"));
        document.dispatchEvent(new Event("touchend"));
      });
    },
    resetPapers() {
      const paperElements = this.$el.querySelectorAll(".paper");
      paperElements.forEach((paper) => {
        this.initPaper(paper);
      });
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Zeyada&display=swap");

img {
  pointer-events: none;
}

body {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 1000px;
  background-image: url("https://www.psdgraphics.com/wp-content/uploads/2022/01/white-math-paper-texture.jpg");
  background-position: center center;
}
.paper {
  background-image: url("https://i0.wp.com/textures.world/wp-content/uploads/2018/10/2-Millimeter-Paper-Background-copy.jpg?ssl=1");
  background-size: 500px;
  background-position: center center;
  padding: 20px 100px;
  transform: rotateZ(-5deg);
  box-shadow: 1px 15px 20px 0px rgba(0, 0, 0, 0.5);
  position: absolute;
}
.paper.heart {
  position: relative;
  width: 200px;
  height: 200px;
  padding: 0;
  border-radius: 50%;
}
.paper.image {
  padding: 10px;
}
.paper.image p {
  font-size: 30px;
}

.paper.heart::after {
  content: "";
  background-image: url("https://cdn.pixabay.com/photo/2016/03/31/19/25/cartoon-1294994__340.png");
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: 150px;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.6;
}
.paper.red {
  filter: hue-rotate(90deg);
}
/* Blue paper */
.paper.blue {
  filter: hue-rotate(180deg);
}

/* Green paper */
.paper.green {
  filter: hue-rotate(270deg);
}

/* Purple paper */
.paper.purple {
  filter: hue-rotate(300deg);
}


.paper:nth-of-type(3n) {
  background-position: left top;
}
p {
  font-family: "Zeyada";
  font-size: 50px;
  color: rgb(0, 0, 100);
  opacity: 0.75;
  user-select: none;
}
@media (min-width: 1424px) {
  img {
    max-height: 50vh;
    width: 100%;
    user-select: none;
  }
}

@media (max-width: 1424px) {
  .paper {
    max-width: 70%;
    height: auto;
  }
}

@media (max-width: 1424px) {
  .paper-image {
    max-width: 400px;
    height: auto;
    display: block;
    margin: 0 auto;
  }
}

@media (min-width: 0px) and (max-width: 900px) {
  .paper-image {
    max-width: 200px;
    height: auto;
    display: block;
    margin: 0 auto;
  }
}
.reset-button {
  background-color: #4a90e2;
  color: #ffffff;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  border-radius: 999px;
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease-in-out;
  outline: none;
  z-index: 999;
}

.reset-button:hover {
  background-color: #357ac0;
}

.reset-button i {
  transform: rotate(90deg);
}
</style>