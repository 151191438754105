<template>
    <div class="envlope-wrapper">
      <div id="envelope" class="close">
        <div class="front flap"></div>
        <div class="front pocket"></div>
        <div class="letter">
          <div class="words line1"></div>
          <div class="words line2"></div>
          <div class="words line3"></div>
          <div class="words line4"></div>
        </div>
        <div class="hearts">
          <div class="heart a1"></div>
          <div class="heart a2"></div>
          <div class="heart a3"></div>
        </div>
      </div>
    </div>
    <div class="reset">
      <button id="open" @click="togglePopup">Open</button>
      <button id="reset" @click="reset">Reset</button>
    </div>
  
    <!-- Popup -->
    <div class="popup" v-if="showPopup">
      <div class="popup-content">
        <span class="close-popup" @click="togglePopup">&times;</span>
        <h2>A Love Note for You</h2>
        <p>{{ popupMessage }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        envelope: null,
        btnOpen: null,
        btnReset: null,
        showPopup: false,
        popupMessage:  `
        Hey my love,

        Every day with you is a gift. Du bringst mir soviel happiness in meinem Leben, and I can't imagine a day without you. 🐥

        Let's create our future together. Ich kann es kaum erwarten, den Rest meines Lebens mit dir zu verbringen.

        You're so incredible that it's almost unfair. But you know what? I'm gonna marry you for that some day.

        Your so gorgeous and cute, so I can't get you out of my head every second or should i say every 247 zeptoseconds. I think about you every day. It is so boring when you're not around. In these moments, I can't wait to see you again.

        You're as beautiful as the flowers in a field, and I cherish every moment we spend together. "Three words, eight letters," I love you more than anything in the world. 💗

        Baby, I really love you for who you are, how you love me and how passionate you are when talking about us. 

        Happy birthday, my love! You're the best thing that ever happened to me, and I'm so grateful for you. Und du weißt, dass ich immer für dich da bin auf süß. I love you more than anything.

        Always yours,

        Your future husband 🐥
      `
        };
    },
    mounted() {
      this.envelope = document.getElementById('envelope');
      this.btnOpen = document.getElementById('open');
      this.btnReset = document.getElementById('reset');
  
      this.envelope.addEventListener('click', this.open);
      this.btnOpen.addEventListener('click', this.togglePopup);
      this.btnReset.addEventListener('click', this.close);
    },
    methods: {
      open() {
        this.envelope.classList.add('open');
        this.envelope.classList.remove('close');
        // wait the heart animation to finish
        setTimeout(() => {
          this.showPopup = true; // Show the popup when the letter is opened
        }, 2400);

      },
      close() {
        this.envelope.classList.add('close');
        this.envelope.classList.remove('open');
        this.showPopup = false;
      },
      togglePopup() {
        this.showPopup = !this.showPopup;
      },
      reset() {
        this.close();
      }
    }
  };
  </script>
  
  <style scoped>
  #envelope {
    position: relative;
    width: 280px;
    height: 180px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    margin: auto;
    top: 150px;
    background-color: #004f79;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }
  
  .front {
    position: absolute;
    width: 0;
    height: 0;
    z-index: 3;
  }
  
  .flap {
    border-left: 140px solid transparent;
    border-right: 140px solid transparent;
    border-bottom: 82px solid transparent;
    border-top: 98px solid #004f79;
    transform-origin: top;
    pointer-events: none;
  }
  
  .pocket {
    border-left: 140px solid #0077B2;
    border-right: 140px solid #0077B2;
    border-bottom: 90px solid #005a8c;
    border-top: 90px solid transparent;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  
  .letter {
    position: relative;
    background-color: #fff;
    width: 90%;
    margin: 5% auto 0;
    height: 90%;
    border-radius: 6px;
    box-shadow: 0 2px 26px rgba(0, 0, 0, 0.12);
  }
  
  .letter:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(215, 227, 239, 0.7), rgba(215, 227, 239, 1));
  }
  
  .words {
    position: absolute;
    left: 10%;
    width: 80%;
    height: 14%;
    background-color: #EEEFF0;
  }
  
  .words.line1 { top: 15%; width: 20%; height: 7%; }
  .words.line2 { top: 30%; }
  .words.line3 { top: 50%; }
  .words.line4 { top: 70%; }
  
  .open .flap {
    transform: rotateX(180deg);
    transition: transform 0.4s ease, z-index 0.6s;
    z-index: 1;
  }
  
  .close .flap {
    transform: rotateX(0deg);
    transition: transform 0.4s 0.6s ease, z-index 1s;
    z-index: 5;
  }
  
  .close .letter {
    transform: translateY(0);
    transition: transform 0.4s ease, z-index 1s;
    z-index: 1;
  }
  
  .open .letter {
    transform: translateY(-60px);
    transition: transform 0.4s 0.6s ease, z-index 0.6s;
    z-index: 2;
  }
  
  /* Hearts */
  .hearts {
    position: absolute;
    top: 90px;
    left: 0;
    right: 0;
    z-index: 2;
  }
  
  .heart {
    position: absolute;
    bottom: 0;
    right: 10%;
    pointer-events: none;
  }
  
  .heart:before,
  .heart:after {
    position: absolute;
    content: "";
    left: 50px;
    top: 0;
    width: 50px;
    height: 80px;
    background: #D00000;
    border-radius: 50px 50px 0 0;
    transform: rotate(-45deg);
    transform-origin: 0 100%;
    pointer-events: none;
  }
  
  .heart:after {
    left: 0;
    transform: rotate(45deg);
    transform-origin: 100% 100%;
  }
  
  .close .heart {
    opacity: 0;
    animation: none;
  }
  
  .a1 {
    left: 20%;
    transform: scale(0.6);
    opacity: 1;
    animation: slideUp 4s linear 1, sideSway 2s ease-in-out 4 alternate;
    animation-fill-mode: forwards;
    animation-delay: 0.7s;
  }
  
  .a2 {
    left: 55%;
    transform: scale(1);
    opacity: 1;
    animation: slideUp 5s linear 1, sideSway 4s ease-in-out 2 alternate;
    animation-fill-mode: forwards;
    animation-delay: 0.7s;
  }
  
  .a3 {
    left: 10%;
    transform: scale(0.8);
    opacity: 1;
    animation: slideUp 7s linear 1, sideSway 2s ease-in-out 6 alternate;
    animation-fill-mode: forwards;
    animation-delay: 0.7s;
  }
  
  @keyframes slideUp {
    0% { top: 0; }
    100% { top: -600px; }
  }
  
  @keyframes sideSway {
    0% { margin-left: 0px; }
    100% { margin-left: 50px; }
  }
  
  /* Envelope Wrapper */
  .envelope-wrapper {
    height: 380px;
  }
  
  /* Reset Button */
  .reset {
    text-align: center;
  }
  
  .reset button {
    font-weight: 800;
    font-style: normal;
    transition: all 0.1s linear;
    -webkit-appearance: none;
    background-color: transparent;
    border: solid 2px #0077B2;
    border-radius: 4px;
    color: #0077B2;
    display: inline-block;
    font-size: 14px;
    text-transform: uppercase;
    margin: 5px;
    padding: 10px;
    line-height: 1em;
    text-decoration: none;
    min-width: 120px;
    cursor: pointer;
  }
  
  .reset button:hover {
    background-color: #0077B2;
    color: #fff;
  }
  
  /* Popup Styles */
  .popup {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
  
  .popup-content {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    text-align: center;
    position: relative;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
  }
  
  .close-popup {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    font-size: 24px;
    color: #888;
  }
  
  .popup-content h2 {
    color: #d00000;
    margin-bottom: 15px;
  }
  
  .popup-content p {
    font-family: 'Zeyada', cursive;
    font-size: 24px;
    line-height: 1.4;
    color: #333;
  }
  </style>